<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap>
        <v-flex xs12>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Orden
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 2" step="2">
                Forma de cierre
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3">
                Finalizan
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content class="pa-0" step="1">
                <div class="py-3 px-4">
                  <v-text-field hide-details box label="Ingrese numero de orden"></v-text-field>
                </div>
                <v-divider></v-divider>
                <div class="py-2 px-2 text-xs-right">
                  <v-btn class="ma-0" flat>Volver</v-btn
                  ><v-btn class="ml-2 ma-0" color="primary" @click="e1 = 2">Continuar</v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content class="pa-0" step="2">
                <div class="py-3 px-4">
                  <v-layout wrap>
                    <v-flex xs12 sm6 lg3>
                      <v-subheader>Fecha de entrega</v-subheader>
                      <v-date-picker
                        v-model="cierre.date"
                        locale="es-ES"
                        full-width
                        reactive
                        class="mb-2"
                      ></v-date-picker>
                    </v-flex>
                    <v-flex xs12 sm6 lg3>
                      <v-subheader>Hora de entrega</v-subheader>
                      <v-time-picker v-model="cierre.time" full-width></v-time-picker>
                    </v-flex>
                    <v-flex xs12 sm12 lg6>
                      <v-subheader>Tipo de cierre</v-subheader>
                      <v-select
                        v-model="cierre.type"
                        hide-details
                        :items="cierres"
                        item-text="value"
                        item-value="id"
                        label="Tipos de cierre"
                        box
                      ></v-select>
                      <v-divider class="my-4"></v-divider>
                      <v-layout v-if="cierre.type === 'rempresa'" wrap>
                        <v-flex xs12>
                          <v-select
                            v-model="cierre.info.en"
                            hide-details
                            :items="[
                              { id: 'en la recepción de la empresa por', value: 'Recepción' },
                              { id: 'por el compañero de trabajo Sr.', value: 'Compañero' },
                              { id: 'por la compañera de trabajo', value: 'Compañera' },
                              { id: 'por el guardia de la empresa', value: 'Guardia' },
                              { id: 'por la secretaria', value: 'Secretaria' }
                            ]"
                            item-text="value"
                            item-value="id"
                            label="Recibido por"
                            box
                          ></v-select>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field v-model="cierre.info.persona" label="Nombre" box hide-details></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-select
                            v-model="cierre.info.porqueno"
                            hide-details
                            :items="[
                              {
                                id:
                                  'La destinataria(o) no pudo recibir las flores personalmente ya que se encontraba en una reunion al momento de la entrega',
                                name: 'En reunión'
                              },
                              {
                                id:
                                  'La destinataria(o) no pudo recibir las flores personalmente ya que se encontraba en colación  al momento de la entrega',
                                name: 'En colación'
                              },
                              {
                                id:
                                  'La destinataria(o) no pudo recibir las flores personalmente, ya que no se encontraba en la oficina al momento de la entrega, sin embargo, nos indican que va a regresar en el trascurso del día',
                                name: 'Salio pero regresa'
                              },
                              {
                                id:
                                  'La destinataria(o) no pudo recibir las flores, ya que, se encontraba ocupada al momento de la entrega para recibirlas personalmente',
                                name: 'Dest ocupada'
                              }
                            ]"
                            item-text="name"
                            item-value="id"
                            label="Datos adicionales"
                            box
                          ></v-select>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else-if="cierre.type === 'redificio'" wrap>
                        <v-flex xs12>
                          <v-text-field v-model="cierre.info.persona" label="Nombre" box hide-details></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field v-model="cierre.info.telefono" label="Teléfono" box hide-details></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else-if="cierre.type === 'pservicio'" wrap>
                        <v-flex xs12>
                          <v-text-field v-model="cierre.info.persona" label="Nombre" box hide-details></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else-if="cierre.type === 'clinica'" wrap>
                        <v-flex xs12>
                          <v-text-field label="Nombre" box hide-details></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-select
                            v-model="cierre.info.clinica"
                            hide-details
                            :items="[
                              {
                                id:
                                  'en la central de correspondencia de la Clinica Las Condes (en esta clinica no se permite la entrega directa en las habitaciones)',
                                name: 'Las Condes'
                              },
                              {
                                id:
                                  'en la recepcion de la Clinica Alemana (en esta clinica no se permite la entrega directa en las habitaciones)',
                                name: 'Alemana'
                              },
                              {
                                id:
                                  'en la recepcion de la Clinica Indisa (en esta clinica no se permite la entrega directa en las habitaciones)',
                                name: 'Indisa'
                              },
                              {
                                id:
                                  'en la recepcion de la Clinica Juan Pablo II (en esta clinica no se permite la entrega directa en las habitaciones)',
                                name: 'Juan Pablo II'
                              },
                              {
                                id:
                                  'en la recepcion de la Clinica UC San Carlos de Apoquindo (en esta clinica no se permite la entrega directa en las habitaciones)',
                                name: 'UC San Carlos'
                              },
                              {
                                id:
                                  'en la central de estafeta de la Clinica Santa María (en esta clinica no se permite la entrega directa en las habitaciones)',
                                name: 'Santa María'
                              },
                              {
                                id:
                                  'en la recepcion de la Clinica Tabancura (en esta clinica no se permite la entrega directa en las habitaciones)',
                                name: 'Tabancura'
                              },
                              {
                                id:
                                  'en la recepcion de la Clinica Sara Moncada (en esta clinica no se permite la entrega directa en las habitaciones)',
                                name: 'Sara Moncada'
                              },
                              {
                                id:
                                  'en la recepcion de la Clinica Las Nieves (en esta clinica no se permite la entrega directa en las habitaciones)',
                                name: 'Las Nieves'
                              },
                              {
                                id:
                                  'en la recepcion de la Clinica Universidad Los Andes (en esta clinica no se permite la entrega directa en las habitaciones)',
                                name: 'Universidad Los Andes'
                              },
                              { id: 'en la recepcion de la Clinica Dávila', name: 'Clinica Dávila' },
                              { id: 'en la recepcion de la Clinica Cordillera', name: 'Clinica Cordillera' },
                              { id: 'en la recepcion de la Clinica Las lilas', name: 'Clinica Las lilas' }
                            ]"
                            item-text="name"
                            item-value="id"
                            label="Clinica"
                            box
                          ></v-select>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else-if="cierre.type === 'otra_persona'" wrap>
                        <v-flex xs12>
                          <v-text-field label="Nombre" box hide-details></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-select
                            v-model="cierre.info.familiar2"
                            hide-details
                            :items="[
                              { id: 'la mama de la destinataria(o), Sra. ', name: 'Mamá' },
                              { id: 'el papa de la destinataria(o), Sr. ', name: 'Papá' },
                              { id: 'el esposo de la destinataria, Sr. ', name: 'Esposo' },
                              { id: 'el hijo de la destinataria(o), ', name: 'Hijo' },
                              { id: 'la hija de la destinataria(o), ', name: 'Hija' },
                              { id: 'la hermana de la destinataria(o), ', name: 'Hermana' },
                              { id: 'el hermano de la destinataria(o), ', name: 'Hermano' },
                              { id: 'la tia de la destinataria(o), Sra. ', name: 'Tia' },
                              { id: 'la tio de la destinataria(o), Sr. ', name: 'Tio' },
                              { id: 'el primo de la destinataria(o), ', name: 'Primo' },
                              { id: 'la prima de la destinataria(o), ', name: 'Prima' },
                              { id: 'la abuela de la destinataria(o), Sra. ', name: 'Abuela' },
                              { id: 'el abuelo de la destinataria(o), Sr. ', name: 'Abuelo' },
                              { id: 'el suegro de la destinataria(o), Sr. ', name: 'Suegro' },
                              { id: 'la suegra de la destinataria(o), Sra. ', name: 'Suegra' },
                              { id: '', name: 'Sin especificar' }
                            ]"
                            item-text="name"
                            item-value="id"
                            label="Familiar"
                            box
                          ></v-select>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else-if="cierre.type === 'velatorio'" wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-model="cierre.info.persona"
                            label="Recibido en velatorio por"
                            box
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else-if="cierre.type === 'vecino'" wrap>
                        <v-flex xs12>
                          <v-select
                            v-model="cierre.info.sexo_vecino"
                            hide-details
                            :items="[{ id: 'la vecina', name: 'Vecina' }, { id: 'el vecino', name: 'Vecino' }]"
                            item-text="name"
                            item-value="id"
                            label="Vecino o vecina?"
                            box
                          ></v-select>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field v-model="cierre.info.persona" label="Nombre" box hide-details></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="cierre.info.direccion"
                            label="Direccion"
                            box
                            hide-details
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field v-model="cierre.info.telefono" label="Teléfono" box hide-details></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else-if="cierre.type === 'especial'" wrap>
                        <v-flex xs12>
                          <v-text-field v-model="cierre.info.persona" label="Nombre" box hide-details></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="cierre.info.relacion"
                            label="Establecer relacion"
                            box
                            hide-details
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field v-model="cierre.info.telefono" label="Teléfono" box hide-details></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else-if="cierre.type === 'institucion'" wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-model="cierre.info.nombre_institucion"
                            label="Nombre de la institución"
                            box
                            hide-details
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field v-model="cierre.info.nombre" label="Nombre" box hide-details></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field v-model="cierre.info.cargo" label="Cargo" box hide-details></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-select
                            v-model="cierre.info.porqueno"
                            hide-details
                            :items="[
                              { id: 'sindatos', name: 'Sin datos' },
                              {
                                id:
                                  'La destinataria(o) no pudo recibir las flores personalmente ya que se encontraba en una reunion al momento de la entrega',
                                name: 'En reunión'
                              },
                              {
                                id:
                                  'La destinataria(o) no pudo recibir las flores personalmente ya que se encontraba en colación  al momento de la entrega',
                                name: 'En colación'
                              },
                              {
                                id:
                                  'La destinataria(o) no pudo recibir las flores personalmente, ya que no se encontraba al momento de la entrega, sin embargo, nos indican que va a regresar en el trascurso del día',
                                name: 'Salio pero regresa'
                              },
                              {
                                id:
                                  'La destinataria(o) no pudo recibir las flores, ya que, se encontraba ocupada al momento de la entrega para recibirlas personalmente',
                                name: 'Destinataria ocupada'
                              }
                            ]"
                            item-text="name"
                            item-value="id"
                            label="Datos adicionales"
                            box
                          ></v-select>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else-if="cierre.type === 'problemas_entrega'" wrap>
                        <v-flex xs12>
                          <v-select
                            v-model="cierre.info.tipo_problema_select"
                            hide-details
                            :items="[
                              { id: 'no habían personas en la dirección de entrega', name: 'Sin moradores' },
                              {
                                id: 'la dirección de entrega indicada es no existe o está incorrecta',
                                name: 'Dirección incorrecta'
                              },
                              {
                                id: 'rechazaron la recepción del producto en la dirección de entrega',
                                name: 'Destinatario no acepta entrega'
                              },
                              {
                                id: 'en la dirección de entrega no reconocen a la persona destinataria',
                                name: 'No reconocen a la persona destinataria'
                              },
                              {
                                id: 'la persona destinataria no se encuentra registrada en la clinica',
                                name: 'Destinataria no se encuentra en la clinica'
                              }
                            ]"
                            item-text="name"
                            item-value="id"
                            label="Seleccione el problema que tuvo: "
                            box
                          ></v-select>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="cierre.info.tipo_problema_select"
                            label="Problema que tuvo:"
                            box
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider></v-divider>
                <div class="py-2 px-2 text-xs-right">
                  <v-btn class="ma-0" flat>Volver</v-btn
                  ><v-btn class="ml-2 ma-0" color="primary" @click="e1 = 3">Continuar</v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card class="mb-5" color="grey lighten-1" height="200px"></v-card>

                <v-btn color="primary" @click="e1 = 1">
                  Continue
                </v-btn>
                <v-btn flat>Cancel</v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
export default {
  data() {
    return {
      e1: 0,
      cierre: {
        time: this.$moment().format('HH:MM'),
        date: this.$moment().format('YYYY-MM-DD'),
        info: {}
      },
      cierres: [
        { id: 'personal', value: 'Personal' },
        { id: 'rempresa', value: 'En empresa' },
        { id: 'redificio', value: 'Por conserje' },
        { id: 'pservicio', value: 'Personal de servicio' },
        { id: 'clinica', value: 'Clínica' },
        { id: 'otra_persona', value: 'Por familiar' },
        { id: 'velatorio', value: 'En velatorio' },
        { id: 'comprador', value: 'Por comprador' },
        { id: 'vecino', value: 'Por vecina(o)' },
        { id: 'especial', value: 'Caso especial' },
        { id: 'institucion', value: 'En institución' },
        { id: 'problemas_entrega', value: 'Problemas de entrega' }
      ]
    }
  }
}
</script>

<style></style>
